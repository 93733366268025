import React, { useRef, useState } from "react";
import { Badge, Card } from "react-bootstrap"
import './TrustDetails.css'
import { Header } from "../Header/Header"
import { Footer } from "../Footer/Footer"

export default function TrustDetails() {
    return (
        <>
            <Header></Header>
            <div className="body" style={{ minHeight: "100vh" }}>
            <h4 className="header"><Badge pill bg="success">TRUST DETAILS</Badge></h4>
            <Card>
            <Card.Body>
                <Badge bg="secondary">TRUST NAME</Badge> Kushal Swadesh Pratistha Group (KSPG) India
                <br/><Badge bg="secondary">FOUNDED ON</Badge> 12th October 2018
                <br/><Badge bg="secondary">PAN #</Badge> AAETK6849F<br/><br/>
                <Badge bg="primary">OBJECTIVES OF THE TRUST</Badge>
                <p>
                 &bull; To initiate Socio-cultural activities for the development of the Society.<br/>
                 &bull; To provide better Health-care facilities by using traditional Herbal medications.<br/>
                 &bull; To inoculcate better Social relationships within family as well as the society by spreading of various messages in relation thereto.<br/>
                 &bull; Organizing various events at different places (both urban and rural) especially for children and women with an aim to restructure the forgotten culture of the lands.<br/>
                 &bull; To provide a platform which shall encourage Traditional craftsperson and artists to showcase their talent and revive the over-looked culture.<br/>
                 &bull; To promote the use of new technology and digital medium in different fields of work, participating in the Digital India movement and encouraging digitization.<br/>
                 &bull; To support research activities and recognize outstanding research contributions in the field of Natural Medicine, Science, and Technology.<br/>
                 &bull; To act as a medium concerning the knowledge exchange process for the benefit of people globally.<br/>
                 &bull; To set up new industries for manufacturing of natural medicine, essential products and electronics.<br/>
                 &bull; Creating job opportunities for individuals and encouraging women empowerment across regions.<br/>
                 &bull; Providing Technological support to different vendors for effective work and better Business Decisions.
                </p>

                <Badge bg="primary">REGISTERED OFFICE</Badge>
                <p>
                    K.S.P.G. India, C/o. K.S. Home-made Products, Utkal Ashram Road, Brahmapur-760 001, Odisha
                </p>

                <Badge bg="primary">AREA OF OPERATION</Badge>
                <p>
                    The Trust may run the trust within Brahmapur and may start any branches or extensions in any part of Odisha and outside Odisha as well.
                </p>

                <Badge bg="primary">THE BOARD OF TRUSTEES</Badge>
                <p>
                    &bull; The board of trustees shall ordinarily consist of not less than three and not more than eleven members and signatories to these presents shall be the first trustees.<br/>
                    &bull; The board of trustees shall be the supreme authority of the trust and all other authorities shall act and operate under the supervision of the trustees and have and exercise such powers as may be delegated to such authority by the Board of Trustees.<br/>
                    &bull; Appointment of the trustees shall be for life term, in case of death of any of the trustee above referred the vacancies so caused may be filled up by the nominee of the deceased with approval of the board of trustees and or by any new member as elected by the Board.<br/>
                    &bull; The co-founders of the trust shall be one of the trustees and after their life the other members of their family of descendants shall be the nominee of the deceased. Vacancies shall be filed first by the spouse else if not present , by the eldest educated descendant of the family. In absence of both, the one elected among the nominee by the Board of Trustee shall be a co-trustee.<br/>
                    &bull; If a co-trustee becomes insolvent, the office of such trustee shall be suspended, till he/she attains solvency. And in case of a trustee committing an offence of moral turpitude, is convicted and punished by a court of law, he/she shall be deemed to have vacated the office and remaining co-founders shall fill his/her seat by appointment of another trustee. So, will be the case in of voluntarily vacating the office by any of the trustees or on becoming disabled due to some physical infirmity. In case of absence of any co-founder, the new trustee shall be appointed as per decision of the board with more than half of the majority.<br/>
                    &bull; While performing the purpose of the trust, if there arose any differences of opinions between the trustees, the decision of hte co-founders shall prevail, and which all concerned shall be appointed as epr decision of the board with more than half of the majority.<br/>
                    &bull; If the trust fails for want of its objects or it becomes impossible to carry out the said purpose due to the reasons un-avoidable, the trustees shall move to the court for applying the "Trust Funds" for some other similar purpose. The "Trust Funds" shall, however, in no case revert to the founder or his legal representatives, as it is a public trust being duly registered.<br/>
                    &bull; No corpus of the trust property or funds shall be utilized for purposes other than those of the trust.
                </p>

                <Badge bg="primary">OFFICE BEARERS</Badge>
                <p>
                    <Badge bg="success">PRESIDENT</Badge>&nbsp;Dr. Debashisha Panda&nbsp;&nbsp;&nbsp;
                    <Badge bg="success">TREASURER</Badge>&nbsp;Mrs. Sarita Panda &nbsp;&nbsp;&nbsp;
                    <Badge bg="success">SECRETARY</Badge>&nbsp;Mr. Rahul Panda &nbsp;
                </p>
                </Card.Body>
                </Card>
            </div>
            <Footer></Footer>
            
        </>
    )
}