import React, { useState } from 'react'
import { Button, Nav, Navbar, Container, NavDropdown } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import './Header.css'

export function Header() {

    const[error, setError] = useState("")
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function handleLogout() {
        setError('')
        try{
            await logout()
            history.push('/login')
        } catch {
            setError('Failed to log out')
        }
    }

    return(
        <>
            <Container style={{ maxWidth: "100%", paddingLeft: '0px', paddingRight: '0px' }}>
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Container>
                    <Link class="link" to="/"><img className="logo"  height={"55px"} width={"50px"} src="kspg_logo_600_600.svg" alt="KSPG India"></img></Link>
                    
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Link class="link" to="/"><Nav.Link href="/trust-details">Home</Nav.Link></Link>
                        <Link class="link" to="/trust-details"><Nav.Link href="/trust-details">Trust Details</Nav.Link></Link>
                        <Link class="link" to="/"><Nav.Link href="/generate-certificate">Certificates</Nav.Link></Link>

                        <NavDropdown title="Staffing" id="collasible-nav-dropdown">
                        <Link class="link" to="/mark-attendance"><NavDropdown.Item href="/">Mark my Attendance</NavDropdown.Item></Link>
                        <Link class="link" to="/apply-leave"><NavDropdown.Item href="/">Apply for Leave</NavDropdown.Item></Link>
                        <Link class="link" to="/view-payslip"><NavDropdown.Item href="/">View my Payslip</NavDropdown.Item></Link>
                        <Link class="link" to="/my-performance"><NavDropdown.Item href="/">My Performance</NavDropdown.Item></Link>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className="header" disabled>Admin Section</NavDropdown.Item>
                        <Link class="link" to="/eis"><NavDropdown.Item href="/">Employee Information System (EIS)</NavDropdown.Item></Link>
                        </NavDropdown>

                        <NavDropdown title="Billing" id="collasible-nav-dropdown">
                        <Link class="link" to="/generate-bill"><NavDropdown.Item href="/">Generate Bill</NavDropdown.Item></Link>
                        <Link class="link" to="/update-bill"><NavDropdown.Item href="/">Update Bill</NavDropdown.Item></Link>
                        <Link class="link" to="/billing-history"><NavDropdown.Item href="/">Billing History</NavDropdown.Item></Link>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className="header" disabled>Admin Section</NavDropdown.Item>
                        <Link class="link" to="/cis"><NavDropdown.Item href="/">Customer Information System (CIS)</NavDropdown.Item></Link>
                        <Link class="link" to="/ais"><NavDropdown.Item href="/">Accounting Information System (AIS)</NavDropdown.Item></Link>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <Nav.Link eventKey={1}>
                        <Link to="/update-profile"><Button variant="warning">Update Profile</Button>{' '}</Link>
                        </Nav.Link>
                        <Nav.Link eventKey={2}>
                            <Button variant="danger" onClick={handleLogout}>Logout</Button>{' '}
                        </Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>
        </>
    )
}