import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import './Login.css'

export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            history.push('/')
        } catch {
            setError('Failed to sign in')
        }

        setLoading(false)
        
    }

    return (
        <>
            <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
            
                <div className="w-100" style={{ maxWidth: "400px" }}>
                <img className="logo" src="./kspgindia-logo.svg" alt="KSPG India"></img>
                    <Card className="card-background">
                        <Card.Body>
                            <h2 className="text-center mb-4">Portal Login</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} />
                                </Form.Group><br/>

                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" ref={passwordRef} />
                                </Form.Group><br/>

                                
                                <Button disabled={loading} className="w-100" variant="danger" type="submit">Log In</Button>
                            </Form>

                            <div className="w-100 text-center mt-3">
                                <Link to='forgot-password'>Forgot Password?</Link>
                            </div>

                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-2">
                    If you don't have login access, kindly reach us at <strong>kspgindiagroup@gmail.com</strong>
                    </div>
                    <div className="return-button">
                    <a href="https://kspgindia.com"><Button className="w-30" variant="warning" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                    </svg> Get back to KSPG India Website</Button></a>
                  </div>
                </div>
            </Container>
        </>
    )
}