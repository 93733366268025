import React, { useRef, useState } from "react";
import { Badge, Card } from "react-bootstrap"
import './Footer.css'

export function Footer() {
    return (
        <>
            <footer id="sticky-footer" class="flex-shrink-0 py-4 bg-light text-black-50">
            <div class="container text-center">
            <small>Copyright &copy; KSPG India 2022. This site is developed and maintained by KSPG India.</small>
            </div>
            </footer>
        </>
    )
}