import React from "react"
import SignUp from "./Signup/Signup"
import Dashboard from "./Dashboard/Dashboard"
import Login from "./Login/Login"
import UpdateProfile from "./UpdateProfile/UpdateProfile"
import TrustDetails from "./TrustDetails/TrustDetails"
import ForgotPassword from "./ForgotPassword/ForgotPassword"
import MarkAttendance from "./Staffing/MarkAttendance/MarkAttendance"
import ViewPayslip from "./Staffing/ViewPayslip/ViewPayslip"
import MyPerformance from "./Staffing/MyPerformance/MyPerformance"
import EIS from "./Staffing/EIS/EIS"
import AIS from "./Billing/AIS/AIS"
import CIS from "./Billing/CIS/CIS"
import GenerateBill from "./Billing/GenerateBill/GenerateBill"
import UpdateBill from "./Billing/UpdateBill/UpdateBill"
import BillingHistory from "./Billing/BillingHistory/BillingHistory"
import ApplyLeave from "./Staffing/ApplyLeave/ApplyLeave"
import { Container } from 'react-bootstrap'
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import PrivateRoute from "./PrivateRoute"
import './style.css'

function App() {
  return (
    
    <Container className="background" style={{ maxWidth: "100%", paddingLeft: '0px', paddingRight: '0px' }}>
            <Router>
              <AuthProvider>
                  <Switch>
                    <PrivateRoute exact path="/" component={Dashboard} />
                    <PrivateRoute path="/update-profile" component={UpdateProfile}/>
                    <PrivateRoute path="/signup" component={SignUp} />
                    <Route path="/login" component={Login} />
                    <Route path="/forgot-password" component={ForgotPassword}/>
                    <PrivateRoute path="/trust-details" component={TrustDetails}/>
                    <PrivateRoute path="/mark-attendance" component={MarkAttendance}/>
                    <PrivateRoute path="/apply-leave" component={ApplyLeave}/>
                    <PrivateRoute path="/view-payslip" component={ViewPayslip}/>
                    <PrivateRoute path="/my-performance" component={MyPerformance}/>
                    <PrivateRoute path="/eis" component={EIS}/>
                    <PrivateRoute path="/cis" component={CIS}/>
                    <PrivateRoute path="/ais" component={AIS}/>
                    <PrivateRoute path="/generate-bill" component={GenerateBill}/>
                    <PrivateRoute path="/update-bill" component={UpdateBill}/>
                    <PrivateRoute path="/billing-history" component={BillingHistory}/>
                  </Switch>
              </AuthProvider>
            </Router>
      </Container>
  )

}

export default App;
