import React, { useRef, useState } from 'react'
import { Card, Button, Form, Badge } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import './MarkAttendance.css'
import { Header } from "../../Header/Header"

export default function MarkAttendance() {
    var today = new Date()
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
    var time = today.getHours() + ":" + today.getMinutes()
    const InTime = useRef()
    const OutTime = useRef()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            
        } catch {
            setError('Failed to mark time')
        }

        setLoading(false)
        
    }

    return(
        <>
            <Header></Header>
            <div className="body" style={{ minHeight: "100vh" }}>
            <h4 className="header"><Badge pill bg="success">MARK MY ATTENDANCE</Badge></h4>
            <Card className="w-100 centered" style={{ maxWidth: "600px" }}>
            <Card.Body>
                <Badge bg="secondary">EMPLOYEE ID</Badge> Placeholder
                <br/><Badge bg="secondary">EMPLOYEE NAME</Badge> Placeholder
                <br/><Badge bg="secondary">MARKING DATE</Badge> {date}
                <br/><Badge bg="secondary">MARKING TIME</Badge> {time}<br/><br/>

                <Form onSubmit={handleSubmit}>
                                <Form.Group id="in-time">
                                    <Form.Label>IN TIME</Form.Label>
                                    <Form.Control type="time" ref={InTime} />
                                </Form.Group><br/>

                                <Form.Group id="out-time">
                                    <Form.Label>OUT TIME</Form.Label>
                                    <Form.Control type="time" ref={OutTime} />
                                </Form.Group><br/>

                                
                                <Button disabled={loading} className="w-100" variant="primary" type="submit">Mark Attendance</Button>
                            </Form>
                
                </Card.Body>
                </Card>
            </div>
        </>
    )
}