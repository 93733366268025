import React, { useRef, useState } from "react";
import { Badge, Card } from "react-bootstrap"
import './CIS.css'
import { Header } from "../../Header/Header"

export default function CIS() {
    return (
        <>
            <Header></Header>
            <div className="body">
            <h4 className="header"><Badge pill bg="success">Customer Information System (CIS)</Badge></h4>
                <Card>
                <Card.Body>
                    <p>This section will contain details about your customers</p>
                </Card.Body>
                </Card>
            </div>
            
        </>
    )
}