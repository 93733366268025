import React, { useRef, useState } from "react";
import { Badge, Card } from "react-bootstrap"
import './EIS.css'
import { Header } from "../../Header/Header"

export default function EIS() {
    return (
        <>
            <Header></Header>
            <div className="body" style={{ minHeight: "100vh" }}>
            <h4 className="header"><Badge pill bg="success">Employee Information System (EIS)</Badge></h4>
                <Card>
                <Card.Body>
                    <p>This section will contain details about your performance</p>
                </Card.Body>
                </Card>
            </div>
            
        </>
    )
}