import React, { useRef, useState } from "react";
import { Badge, Card } from "react-bootstrap"
import './BillingHistory.css'
import { Header } from "../../Header/Header"

export default function BillingHistory() {
    return (
        <>
            <Header></Header>
            <div className="body">
            <h4 className="header"><Badge pill bg="success">Billing History</Badge></h4>
                <Card>
                <Card.Body>
                    <p>This section will allow to view billing history</p>
                </Card.Body>
                </Card>
            </div>
            
        </>
    )
}