import React, { useState } from 'react'
import { Card, Button, Alert, Nav, Navbar, Container, NavDropdown, Badge } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import './Dashboard.css'
import { Header } from "../Header/Header"
import { Footer } from "../Footer/Footer"

export default function Dashboard() {

    const[error, setError] = useState("")
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function handleLogout() {
        setError('')
        try{
            await logout()
            history.push('/login')
        } catch {
            setError('Failed to log out')
        }
    }

    return(
        <>
            <Header></Header>
            <Container style={{ minHeight: "100vh", maxWidth: "100%", paddingLeft: '0px', paddingRight: '0px', paddingTop: '25px' }}>
                <Container className="d-flex justify-content-center">
                    <div className="w-100" style={{ maxWidth: "100vh" }}>
                        <Card>
                            <Card.Body>
                                <h2 className="text-center mb-4 heading">Dashboard</h2>
                                {error && <Alert variant="danger">{error}</Alert>}
                                <p><strong>Registered Email ID:</strong> {currentUser.email}</p>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            </Container>
            <Footer></Footer>
        </>
    )
}