import React, { useRef, useState } from "react";
import { Badge, Card } from "react-bootstrap"
import './UpdateBill.css'
import { Header } from "../../Header/Header"

export default function UpdateBill() {
    return (
        <>
            <Header></Header>
            <div className="body">
            <h4 className="header"><Badge pill bg="success">Update Bill</Badge></h4>
                <Card>
                <Card.Body>
                    <p>This section will allow to update bill</p>
                </Card.Body>
                </Card>
            </div>
            
        </>
    )
}