import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const app = firebase.initializeApp({
    apiKey: "AIzaSyDNPEwWNvnZpgCqVc1zyaEHOIY62ANSmsE",
    authDomain: "kspgindia2018.firebaseapp.com",
    projectId: "kspgindia2018",
    storageBucket: "kspgindia2018.appspot.com",
    messagingSenderId: "516679243386",
    appId: "1:516679243386:web:6c32062370aa70f9adcdc3",
    measurementId: "G-CB54EEQC0V"
})

export const auth = app.auth()
export default app